<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <v-simple-table v-if="dataFetched">
          <template v-slot:default>
            <thead></thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">ID</td>
                <td>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex pr-2"
                        ><span class="align-self-center">{{
                          data.id
                        }}</span></v-col
                      >
                    </v-row>
                  </v-container>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">UUID</td>
                <td>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex pr-2"
                        ><span class="align-self-center">{{
                          data.uuid
                        }}</span></v-col
                      >
                    </v-row>
                  </v-container>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Name</td>
                <td>
                  <DataPatcher
                    :itemValue="data.name"
                    itemName="name"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Firmenname</td>
                <td>
                  <DataPatcher
                    :itemValue="data.legalName"
                    itemName="legalName"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Rechtsform</td>
                <td>
                  <DataPatcher
                    :itemValue="data.legalForm"
                    itemName="legalForm"
                    :route="patchRoute"
                    inputElement="select"
                    inputType=""
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Straße</td>
                <td>
                  <DataPatcher
                    :itemValue="data.street"
                    itemName="street"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Hausnummer</td>
                <td>
                  <DataPatcher
                    :itemValue="data.streetNumber"
                    itemName="streetNumber"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Postleitzahl</td>
                <td>
                  <DataPatcher
                    :itemValue="data.zipCode"
                    itemName="zipCode"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Ort</td>
                <td>
                  <DataPatcher
                    :itemValue="data.town"
                    itemName="town"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Land</td>
                <td>
                  <DataPatcher
                    :itemValue="data.country"
                    itemName="country"
                    :route="patchRoute"
                    inputElement="select"
                    inputType=""
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">E-Mail</td>
                <td>
                  <DataPatcher
                    :itemValue="data.email"
                    itemName="email"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="email"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Telefon</td>
                <td>
                  <DataPatcher
                    :itemValue="data.phone"
                    itemName="phone"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">Fax</td>
                <td>
                  <DataPatcher
                    :itemValue="data.fax"
                    itemName="fax"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                  ></DataPatcher>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold">URL</td>
                <td>
                  <DataPatcher
                    :itemValue="data.url"
                    itemName="url"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="url"
                  ></DataPatcher>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataPatcher from "@/components/ui/DataPatcher.vue";
export default {
  name: "Basedata",

  components: {
    PageHeader,
    Subnavigation,
    DataPatcher,
  },

  data() {
    return {
      pageTitle: "Basedata",
      pageDescription:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non officiis accusantium omnis ad? Porro ea perferendis veritatis repellat sit et nulla quae ab omnis, eos beatae facilis obcaecati aliquam harum!",
      title: "",
      data: [],
      preloader: false,
      editName: false,
      dataFetched: false,
      patchRoute: "companies/" + this.$route.params.id,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;

      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest(
        "companies/" + this.$route.params.id
      ); // await data from mixin
      this.preloader = false;
      this.dataFetched = true;
      this.data = response.data.data;
      this.title = response.data.data.name;
    },
  },

  mixins: [apiRequest],
};
</script>
