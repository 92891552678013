<template>
  <v-list nav dense class="ml-4 elevation-1">
    <!-- <v-subheader>{{ pageName }}</v-subheader> -->
    <v-list-item-group color="primary">
      <div v-for="(route, i) in computedRoute" :key="i">
        <div v-if="route.inNav">
          <v-list-item :to="{ path: route.path }">
            <v-list-item-icon v-if="route.icon && route.icon !== ''">
              <v-icon small>{{ route.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="route.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data: () => ({
    activeItem: null,
    children: [],
  }),

  computed: {
    pageName: function() {
      let matched = this.$route.matched;
      let matchedLength = matched.length;
      return this.$route.matched[matchedLength - 2].name;
    },

    computedRoute: function() {
      let children = [];
      let curRoute = this.$route;

      let matched = curRoute.matched;
      let matchedLength = matched.length;

      let subRoute = this.$router.options.routes.find(
        (route) => route.path === matched[0].path
      );

      if (matchedLength > 2) {
        subRoute = subRoute.children.find(
          (route) => route.path === matched[1].path
        );
      } else if (matchedLength > 3) {
        subRoute = subRoute.children.find(
          (route) => route.path === matched[2].path
        );
      } else if (matchedLength > 4) {
        subRoute = subRoute.children.find(
          (route) => route.path === matched[3].path
        );
      } else if (matchedLength > 5) {
        subRoute = subRoute.children.find(
          (route) => route.path === matched[4].path
        );
      } /* you can do that infinetly */

      if (subRoute && Array.isArray(subRoute.children)) {
        children = subRoute.children;
      } else if (subRoute) {
        children = [];
      }

      return children;
    },
  },

  name: "Subnavigation",
};
</script>
