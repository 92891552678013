<template>
  <header id="page-header">
    <h1>{{pageTitle}}</h1>
    <span v-if="pageDescription && pageDescription !== ''" id="page-intro" v-html="pageDescription"></span>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
  props: ['pageTitle', 'pageDescription']
}
</script>